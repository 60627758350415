<template>
  <div class="pb-2">
    <div v-if="this.loadpage == false" class="text-center pt-3">
      <b-spinner
        style="width: 5rem; height: 5rem;"
        variant="primary"
        label="Large Spinner"
      />
    </div>
    <b-card v-else class="d-none d-sm-block">
      <b-tabs v-model="tabsIndex">
        <b-tab title="Balance เงินไทย">
          <b-card no-body>
            <b-card-body class="d-flex pt-1">
              <div class="d-flex align-items-center mb-1 ">
                <span class="px-0 col-1"> วันที่: </span>
                <flat-pickr v-model="date" class="form-control" :config="datePickerConfig"/>
                <span class="px-1"> - </span>
                <span class="px-0 col-1"> ถึงวันที่: </span>
                <flat-pickr v-model="dueDate" class="form-control mr-1" :config="datePickerConfig"/>
                <b-button variant="primary" @click="getBalance()">ค้นหา</b-button>
              </div>
            </b-card-body>
            <b-table
              :items="this.balanceList"
              :fields="bahtFields"
              responsive
              class="position-relative"
            >
              <template #cell(balanceDate)="data">
                {{formatDatetime(data.value)}}
              </template>
              <template #cell(description)="data">
                <span v-if="data.item.income != null">{{data.item.description}}</span>
                <span v-else class="pl-1">{{data.item.description}}</span>
              </template>
              <template #cell(income)="data">
                <div
                  v-if="data.item.income != null"
                  style="color:green"
                  class="text-right"
                >
                  + {{format_price(data.value)}}
                </div>
              </template>
              <template #cell(expense)="data">
                <div
                  v-if="data.item.expense != null"
                  style="color:red"
                  class="text-right"
                >
                  - {{format_price(data.value)}}
                </div>
              </template>
              <template #cell(balance)="data">
                <div class="text-right">
                  {{format_price(data.value)}}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>

        <b-tab title="Balance เงินหยวน">
          <b-card no-body>
            <b-card-body class="d-flex pt-1">
              <div class="d-flex align-items-center mb-1 ">
                <span class="px-0 col-1"> วันที่: </span>
                <flat-pickr v-model="dateYuan" class="form-control" :config="datePickerConfig"/>
                <span class="px-1"> - </span>
                <span class="px-0 col-1"> ถึงวันที่: </span>
                <flat-pickr v-model="dueDateYuan" class="form-control mr-1" :config="datePickerConfig"/>
                <b-button variant="primary" @click="getYuanBalance()">ค้นหา</b-button>
              </div>
            </b-card-body>
            <b-table
              :items="this.yaunBalanceList"
              :fields="yuanFields"
              responsive
              class="position-relative"
            >
              <template #cell(balanceDate)="data">
                {{formatDatetime(data.value)}}
              </template>
              <template #cell(description)="data">
                <span v-if="data.item.income != null">{{data.value}}</span>
                <span v-else class="pl-1">{{data.value}}</span>
              </template>
              <template #cell(income)="data">
                <div
                  v-if="data.item.income != null"
                  style="color:green"
                  class="text-right"
                >
                  + {{format_price(data.value)}}
                </div>
              </template>
              <template #cell(expense)="data">
                <div
                  v-if="data.item.expense != null"
                  style="color:red"
                  class="text-right"
                >
                  - {{format_price(data.value)}}
                </div>
              </template>
              <template #cell(balance)="data">
                <div class="text-right">
                  {{format_price(data.value)}}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTabs, BTab, BSpinner, BTable, BButton
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import { header_token } from '@/views/token'
import { format_price, formatDatetime } from '@/views/global_function.js'
import {jjCargoService_dotnet} from '@/views/global.js'

export default {
  name: 'JjcargoFrontendBalance',

  components: {
    BCard, BCardHeader, BCardBody, BTabs, BTab, BSpinner, BTable, BButton,
    flatPickr,
  },

  data() {
    return {
      tabsIndex: 0,

      date: '',
      dueDate: '',
      dateYuan: '',
      dueDateYuan: '',
      datePickerConfig: {
        altInput: true,
        altFormat: "d-m-Y",
      },

      balanceList: [],
      yaunBalanceList: [],
      bahtFields: [
        { key: 'balanceDate', label: 'วันที่' },
        { key: 'description', label: 'รายละเอียด', thClass: 'text-center', thStyle: { width: "30%" } },
        { key: 'income', label: 'เข้า', thClass: 'text-center' },
        { key: 'expense', label: 'ออก', thClass: 'text-center' },
        { key: 'balance', label: 'คงเหลือ(บาท)', thClass: 'text-center' },
      ],
      yuanFields: [
        { key: 'balanceDate', label: 'วันที่' },
        { key: 'description', label: 'รายละเอียด', thClass: 'text-center', thStyle: { width: "30%" } },
        { key: 'income', label: 'เข้า', thClass: 'text-center' },
        { key: 'expense', label: 'ออก', thClass: 'text-center' },
        { key: 'balance', label: 'คงเหลือ(หยวน)', thClass: 'text-center' },
      ],

      loadpage: true
    };
  },

  async created() {
    this.loadpage = false
    await this.getBalance()
    await this.getYuanBalance()
    this.loadpage = true
  },

  methods: {
    format_price, formatDatetime,
    async getBalance() {
      let response = await axios.post(
        jjCargoService_dotnet + 'Balance/get-baht-balance',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId')),
          "startDate": this.date,
          "endDate": this.dueDate,
        },
        { headers: header_token }
      );
      this.date = response.data.startDate
      this.dueDate = response.data.endDate
      this.balanceList = response.data.balance

    },
    async getYuanBalance() {
      let response = await axios.post(
        jjCargoService_dotnet + 'Balance/get-yuan-balance',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId')),
          "startDate": this.dateYuan,
          "endDate": this.dueDateYuan,
        },
        { headers: header_token }
      );
      this.dateYuan = response.data.startDate
      this.dueDateYuan = response.data.endDate
      this.yaunBalanceList = response.data.yuanBalance
    },

  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>